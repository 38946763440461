import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import AppointmentDetails from './AppointmentDetails';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithAuth } from '../utils/apiHelper';

const ROW_HEIGHT = 45;
const HOUR_CELL_WIDTH = 80;
const CELL_WIDTH = 225; // Width for sm and md screens

const ScheduleTable = ({ appointments, employees, onBookingClick, dia, sucursal, updateAppointments }) => {
  const [updatedAppointments, setUpdatedAppointments] = useState([]);
  const [loadingState, setLoadingState] = useState({});
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    setUpdatedAppointments(appointments || []);
  }, [appointments]);

  useEffect(() => {
    if (sucursal?.hora_inicio && sucursal?.hora_fin) {
      setStartTime(sucursal.hora_inicio.slice(0, 5));
      setEndTime(sucursal.hora_fin.slice(0, 5));
    }
  }, [sucursal]);

  // Check screen size to adjust the table behavior
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1024); // Set to true for md and below (lg breakpoint in Tailwind)
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const generateTimeSlots = useCallback((start, end) => {
    const slots = [];
    let current = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');

    while (current.isBefore(endTime)) {
      slots.push(current.format('HH:mm'));
      current.add(30, 'minutes');
    }
    return slots;
  }, []);

  const timeSlots = startTime && endTime ? generateTimeSlots(startTime, endTime) : [];



  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Remueve los acentos
  };

  const isEmployeeWorking = useCallback((time, employee) => {
    const currentTime = moment(time, 'HH:mm');
    return employee.horarios.some(horario => {
      const normalizedDiaSemana = removeAccents(horario.dia_semana.toLowerCase());
      const normalizedDia = removeAccents(dia.toLowerCase());
      const startTime = moment(horario.hora_inicio, 'HH:mm');
      const endTime = moment(horario.hora_fin, 'HH:mm');
      
      return normalizedDiaSemana === normalizedDia && currentTime.isBetween(startTime, endTime, null, '[)');
    });
  }, [dia]);
  
  

  const getAppointmentsForTime = useCallback((time, employeeId) => {
    if (!updatedAppointments) return [];
    return updatedAppointments.filter(appointment => {
      const startTime = moment(`${appointment.fecha}T${appointment.hora}`);
      const durationMinutes = (appointment.duracion.hours || 0) * 60 + (appointment.duracion.minutes || 0);
      const endTime = startTime.clone().add(durationMinutes, 'minutes');
      const currentTime = moment(time, 'HH:mm');
      currentTime.set({ year: startTime.year(), month: startTime.month(), date: startTime.date() });
      return appointment.empleado_id === employeeId && currentTime.isBetween(startTime, endTime, null, '[)');
    });
  }, [updatedAppointments]);

  const handleConfirm = async (id) => {
    setLoadingState({ [id]: 'confirming' });
    try {
      await fetchWithAuth('/turnos/confirmar/turno', {
        method: 'POST',
        body: JSON.stringify({ turno_id: id }),
      });
      toast.success('Turno confirmado exitosamente');
      updateAppointments();
    } catch (error) {
      console.error('Error confirming appointment:', error);
      toast.error('Error al confirmar el turno: ' + error.message);
    } finally {
      setLoadingState({});
    }
  };

  const handleCancel = async (id, cancelReason) => {
    setLoadingState({ [id]: 'canceling' });
    try {
      await fetchWithAuth('/turnos/cancelar/turno', {
        method: 'POST',
        body: JSON.stringify({ turno_id: id, motivo_cancelacion: cancelReason }),
      });
      toast.success('Turno cancelado exitosamente');
      updateAppointments();
    } catch (error) {
      console.error('Error canceling appointment:', error);
      toast.error('Error al cancelar el turno: ' + error.message);
    } finally {
      setLoadingState({});
    }
  };

  const handlePay = async (id, paymentMethod, selectedServices) => {
    setLoadingState({ [id]: 'paying' });
    try {
      await fetchWithAuth('/operaciones/crear/pago', {
        method: 'POST',
        body: JSON.stringify({ turno_id: id, metodo_pago: paymentMethod, servicios: selectedServices }),
      });
      toast.success('Pago realizado exitosamente');
      updateAppointments(); // Refresh appointments after successful payment
    } catch (error) {
      console.error(error);
      toast.error('Error al realizar el pago: ' + error.message);
    } finally {
      setLoadingState({});
    }
  };

  const occupiedSlots = {};

  return (
    <div className={`mt-6 max-w-full ${isSmallScreen ? 'overflow-x-auto' : ''}`}>
      <div className={`w-full relative shadow-md rounded-lg ${isSmallScreen ? 'max-h-screen' : ''}`} style={{ overflowY: 'auto' }}>
        <table className={`w-full table-fixed divide-x divide-gray-200 bg-white ${isSmallScreen ? 'sm:table-auto' : ''}`}>
          <thead className="bg-blue-600 text-white z-10 sticky top-0">
            <tr>
              <th
                className="px-1 md:px-4 py-2 text-center border border-black text-xs leading-4 font-medium uppercase tracking-wider sticky left-0 bg-blue-600 z-20"
                style={{ width: `${HOUR_CELL_WIDTH}px` }}
              >
                Hora
              </th>
              {employees.map((employee) => (
                <th
                  key={employee.empleado_id}
                  className="px-4 py-2 border border-black text-center text-xs leading-4 font-medium uppercase tracking-wider sticky top-0 bg-blue-600 z-10"
                  style={{ width: isSmallScreen ? `${CELL_WIDTH}px` : 'auto' }}
                >
                  {employee.nombre} {employee.apellido}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {timeSlots.length === 0 ? (
              <tr>
                <td colSpan={employees.length + 1} className="text-center py-4">
                  Cargando horarios...
                </td>
              </tr>
            ) : (
              timeSlots.map((time, index) => (
                <tr key={index} style={{ height: `${ROW_HEIGHT}px` }}>
                  <td
                    className="px-1 md:px-4 py-2 text-center text-xs leading-5 font-medium text-gray-900 bg-blue-100 border border-blue-400 sticky left-0 z-10"
                    style={{ width: `${HOUR_CELL_WIDTH}px` }}
                  >
                    {time}
                  </td>
                  {employees.map((employee) => {
                    const working = isEmployeeWorking(time, employee);
                    const cellClassName = `p-0 text-center text-xs leading-5 font-medium text-gray-900 h-full w-full`;
                    const empAppointments = getAppointmentsForTime(time, employee.empleado_id);

                    if (empAppointments.length > 0) {
                      const appointment = empAppointments[0];
                      const startTime = moment(`${appointment.fecha}T${appointment.hora}`);
                      const durationMinutes = (appointment.duracion.hours || 0) * 60 + (appointment.duracion.minutes || 0);
                      const rowSpan = Math.ceil(durationMinutes / 30);
                      if (occupiedSlots[`${employee.empleado_id}-${index}`]) {
                        return null;
                      }
                      for (let i = 0; i < rowSpan; i++) {
                        occupiedSlots[`${employee.empleado_id}-${index + i}`] = true;
                      }
                      return (
                        <td
                          key={appointment.turno_id}
                          rowSpan={rowSpan}
                          className={`${cellClassName} border border-black p-0`}
                          style={{ height: `${ROW_HEIGHT * rowSpan}px`, width: isSmallScreen ? `${CELL_WIDTH}px` : 'auto' }}
                        >
                          <AppointmentDetails
                            appointment={appointment}
                            onConfirm={handleConfirm}
                            onCancel={handleCancel}
                            onPay={handlePay}
                            loadingState={loadingState[appointment.turno_id]}
                            className="h-full w-full"
                          />
                        </td>
                      );
                    }

                    return (
                      <td
                        key={employee.empleado_id}
                        onClick={() => onBookingClick(employee, time)}
                        className={`${cellClassName} ${working ? 'bg-green-50 hover:bg-green-100' : 'bg-red-200 hover:bg-red-300'} cursor-pointer border border-black`}
                        style={{ width: isSmallScreen ? `${CELL_WIDTH}px` : 'auto', height: `${ROW_HEIGHT}px` }}
                      >
                        <button className={`${working ? 'text-blue-500 ' : ''} hover:text-blue-700`}>
                          {working ? 'Libre' : 'No disponible'}
                        </button>
                      </td>
                    );
                  })}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScheduleTable;
