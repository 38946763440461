import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../utils/apiHelper';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { FaSearch, FaFilter, FaCalendarAlt } from 'react-icons/fa';

const Estadisticas = () => {
  const [datos, setDatos] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filter, setFilter] = useState('');

  // Inicializa el rango de fechas al mes actual
  useEffect(() => {
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
    const end = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];
    setStartDate(start);
    setEndDate(end);
  }, []);

  // Vuelve a cargar las estadísticas cuando se cambian las fechas
  useEffect(() => {
    if (startDate && endDate) {
      fetchEstadisticas();
    }
  }, [startDate, endDate]);

  // Función para obtener las estadísticas desde el servidor
  const fetchEstadisticas = async () => {
    setLoading(true);
    try {
      const response = await fetchWithAuth(`/operaciones/estadisticas?fecha_inicio=${startDate}&fecha_fin=${endDate}`);
      if (response && Array.isArray(response)) {
        const empleadosMap = {};
        const serviciosMap = {};
        const totalPorServicio = {};

        // Se recorren los datos para sumar las cantidades de cada servicio por empleado
        const datosArray = response.map(row => {
          empleadosMap[row.empleado_id] = row.empleado_nombre;
          serviciosMap[row.servicio_id] = row.servicio_nombre;

          // Sumar trabajos por servicio (convertir a número para sumar correctamente)
          totalPorServicio[row.servicio_id] = (totalPorServicio[row.servicio_id] || 0) + parseInt(row.cantidad_trabajos);

          return row;
        });

        // Se ordenan los servicios por el total de trabajos
        const serviciosOrdenados = Object.entries(totalPorServicio)
          .map(([id, total]) => ({
            servicio_id: id,
            nombre: serviciosMap[id],
            total,
          }))
          .sort((a, b) => b.total - a.total); // Ordenar por total de trabajos (descendente)

        setDatos(datosArray);
        setEmpleados(Object.entries(empleadosMap).map(([id, nombre]) => ({ empleado_id: id, nombre })));
        setServicios(serviciosOrdenados);
      } else {
        toast.warn('No se encontraron datos para el rango seleccionado.');
      }
    } catch (error) {
      toast.error('Error al obtener estadísticas');
    } finally {
      setLoading(false);
    }
  };

  // Función para obtener la cantidad de trabajos de un servicio para un empleado específico
  const getCantidadPorServicio = (empleadoId, servicioId) => {
    // Filtrar los datos y sumar las cantidades
    const trabajosEmpleado = datos.filter(d => d.empleado_id === empleadoId && d.servicio_id === servicioId);
    return trabajosEmpleado.reduce((total, item) => total + parseInt(item.cantidad_trabajos), 0);
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-700">Estadísticas</h2>
      <div className="mb-4 flex flex-wrap items-center justify-between gap-4 bg-gray-100 p-4 rounded-lg shadow-md">
        {/* Filtros */}
        <div className="flex items-center gap-2">
          <FaCalendarAlt className="text-gray-500" />
          <label className="text-sm text-gray-700">Inicio:</label>
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="p-2 border rounded text-sm" />
        </div>
        <div className="flex items-center gap-2">
          <FaCalendarAlt className="text-gray-500" />
          <label className="text-sm text-gray-700">Fin:</label>
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="p-2 border rounded text-sm" />
        </div>
        <div className="flex items-center gap-2">
          <FaFilter className="text-gray-500" />
          <input
            type="text"
            placeholder="Buscar empleado..."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="p-2 border rounded text-sm"
          />
        </div>
        <button onClick={() => fetchEstadisticas()} disabled={loading} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center text-sm">
          {loading ? <ClipLoader size={15} color={'#fff'} /> : <FaSearch className="mr-2" />}Buscar
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <ClipLoader size={50} color={'#3498db'} />
        </div>
      ) : (
        <div className="mt-4 border rounded-lg shadow-lg overflow-x-auto">
          <table className="w-full bg-white text-sm">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-3 border-b-2 border-gray-300 text-left text-gray-600 uppercase sticky left-0 bg-gray-200 z-10">Empleado</th>
                {servicios.map(servicio => (
                  <th key={servicio.servicio_id} className="px-4 py-3 border-b-2 border-gray-300 text-left text-gray-600 uppercase">
                    {servicio.nombre} ({servicio.total})
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {empleados.filter(empleado => empleado.nombre.toLowerCase().includes(filter.toLowerCase())).map((empleado, index) => (
                <tr key={empleado.empleado_id} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100`}>
                  <td className="border px-4 py-3 sticky left-0 bg-white z-10">{empleado.nombre}</td>
                  {servicios.map(servicio => (
                    <td key={servicio.servicio_id} className="border px-4 py-3 text-center">
                      {getCantidadPorServicio(empleado.empleado_id, servicio.servicio_id)}
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                <td className="border px-4 py-3 sticky left-0 bg-white z-10 font-bold">Totales</td>
                {servicios.map(servicio => (
                  <td key={servicio.servicio_id} className="border px-4 py-3 text-center font-bold">
                    {servicio.total}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Estadisticas;
