import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../utils/apiHelper';
import moment from 'moment';
import 'moment/locale/es';
import { FaCalendarAlt, FaSearch } from 'react-icons/fa';
import { BiLoaderAlt } from 'react-icons/bi';

moment.locale('es');

const TurnosCancelados = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [cancellations, setCancellations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // Inicialización de fechas
  useEffect(() => {
    const today = new Date();
    const defaultStartDate = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
    const defaultEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    fetchCancellations(defaultStartDate, defaultEndDate);
  }, []);

  const fetchCancellations = async (start, end) => {
    setLoading(true);
    try {
      const response = await fetchWithAuth(
        `/turnos/cancelados?fechaInicio=${start}&fechaFin=${end}`
      );
      setCancellations(response || []);
    } catch (error) {
      console.error('Error al obtener turnos cancelados:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    fetchCancellations(start, end);
  };

  const filteredCancellations = cancellations.filter((cancel) =>
    `${cancel.cliente_nombre} ${cancel.cliente_apellido}`.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container mx-auto p-6 bg-gradient-to-b from-red-50 to-red-100 rounded-lg shadow-lg">
      <h2 className="text-4xl font-bold text-red-700 text-center mb-8">
        Turnos Cancelados
      </h2>
      <div className="flex flex-wrap items-center gap-4 mb-6 bg-white p-6 rounded-lg shadow-md border border-red-300">
        <div className="flex items-center gap-2">
          <FaCalendarAlt className="text-red-500" />
          <label className="text-sm text-gray-700">Inicio:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => handleDateChange(e.target.value, endDate)}
            className="p-2 border rounded text-sm focus:ring-2 focus:ring-red-500"
          />
        </div>
        <div className="flex items-center gap-2">
          <FaCalendarAlt className="text-red-500" />
          <label className="text-sm text-gray-700">Fin:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => handleDateChange(startDate, e.target.value)}
            className="p-2 border rounded text-sm focus:ring-2 focus:ring-red-500"
          />
        </div>
        <div className="flex items-center gap-2 w-full sm:w-auto">
          <FaSearch className="text-gray-500" />
          <input
            type="text"
            placeholder="Buscar por cliente"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="p-2 border rounded text-sm w-full sm:w-auto focus:ring-2 focus:ring-red-500"
          />
        </div>
        <button
          onClick={() => fetchCancellations(startDate, endDate)}
          disabled={loading}
          className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center"
        >
          {loading ? <BiLoaderAlt className="animate-spin mr-2" /> : 'Buscar'}
        </button>
      </div>

      {loading ? (
        <p className="text-center text-gray-600">Cargando turnos cancelados...</p>
      ) : filteredCancellations.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredCancellations.map((cancel, index) => (
            <div
              key={index}
              className="p-6 bg-white rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow"
            >
              <h3 className="text-lg font-bold text-gray-800">
                {cancel.cliente_nombre} {cancel.cliente_apellido || ''}
              </h3>
              <p className="text-sm text-gray-600">
                <strong>Motivo:</strong> {cancel.motivo_cancelacion || 'Sin motivo'}
              </p>
              <p className="text-sm text-gray-600">
                <strong>Empleado:</strong> {cancel.empleado_nombre || 'No especificado'}
              </p>
              <p className="text-sm text-gray-500">
                Cancelado el{' '}
                {moment(cancel.fecha_cancelacion).format('D [de] MMMM [de] YYYY [a las] HH:mm')}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500">No se encontraron turnos cancelados.</p>
      )}
    </div>
  );
};

export default TurnosCancelados;
